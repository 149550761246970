import Phaser from "phaser";
import Constants from "../../configs/constants";
import Explosion from "../explosion";
import Main from "../../states/main";
import Scaling from "../../configs/scaling";
import Difficulty from "../../configs/difficulty";
import sessionSaveFile from "@/utils/game/SessionSaveFile";

export default class BaseEnemy extends Phaser.GameObjects.Sprite {
    enemyType: string
    category: string;
    isPaused: boolean
    isOutOfBounds: boolean;
    maxHealth: number;
    health: number;
    points: number;
    speed: number; // NOTE: speed is going to be faster on screen that has higher refresh rate
    shootTimer!: Phaser.Time.TimerEvent;
    emitter!: Phaser.GameObjects.Particles.ParticleEmitter;
    damageTimer!: Phaser.Time.TimerEvent;
    allStates!: string[]
    styleAmount: number

    speedAccelerate: number;

    declare scene: Main;
    constructor(scene: Phaser.Scene, frame: string) {
        super(scene, 0, 0, 'enemy', frame);
        this.scene.add.existing(this);

        //Set physics
        this.scene.physics.world.enable(this);

        //Set data
        this.enemyType = "";
        this.category = Constants.CATEGORY_ENEMY;
        this.isPaused = false
        this.isOutOfBounds = false;
        this.maxHealth = this.health = this.points = this.speed = 1;
        this.speedAccelerate = 0
        this.styleAmount = 0

        //Set depth
        this.setDepth(Constants.DEPTH_ENEMY);

        this.setRandomSpawnPosition()

        // player speed up
        this.scene.game.events.on("speedUp", (speed: number) => {
            this.speedAccelerate = speed;
            this.speed = Scaling.SPEED_ENEMY * Difficulty.SPEED_MULTIPLIER + this.speedAccelerate
        })
    }

    update() {
        if (!this.active || this.isPaused)
            return;

        //Move down
        this.y += this.speed;

        //Check boundaries
        this.isOutOfBounds = this.y > this.scene.sys.canvas.height + this.displayHeight * 2;
        this.setActive(!this.isOutOfBounds)

    }

    shoot(speed: number) {
        this.emit("shoot", speed);
    }

    //Life & death
    hit(skipDamageEffect = false) {
        this.health--;

        //Set frame or set game over
        if (this.health === 0) {
            this.death(false);
        } else {
            this.updateState();

            //Show damage effect
            if (!skipDamageEffect && this.active) {
                this.damageEffect();
            }
        }
    }

    randomXPosition(): number {
        const offset = Scaling.getPixelbyDPR(this.displayWidth / 4)
        return Phaser.Math.RND.between(offset, this.scene.cameras.main.width - offset);
    }

    setRandomSpawnPosition() {
        this.setPosition(this.randomXPosition(), -this.displayHeight / 2)
    }

    alive() {
        this.setActive(true)
        this.setVisible(true)
        this.setRandomSpawnPosition()
        this.health = this.maxHealth
        this.GetAllStateNames();
        this.updateState()
    }

    death(silent: boolean, receivePoints = true) {
        if (!this.active)
            return;

        //Cleanup
        sessionSaveFile.incrementValue('killedEnemies', 1);
        this.setVisible(false);
        this.setActive(false)
        if (this.shootTimer) {
            this.shootTimer.remove();
        }
        if (this.emitter) {
            this.scene.particleManager.removeEmitter(this.emitter);
        }

        //Boom
        if (!silent) {
            const points = receivePoints ? this.points : 0
            this.emit("death", points, this);
            this.scene.sound.add("effect-death").play();
            new Explosion(this.scene, this.x, this.y);
        }
    }

    damageEffect() {
        if (!this.scene) { return false }

        if (this.damageTimer) {
            this.damageTimer.remove();
            this.clearTint();
        }

        //Begin animation
        this.damageTimer = this.scene.time.addEvent({
            delay: 30,
            repeat: 3,
            startAt: 30,
            callback: () => {
                if (this.isTinted) {
                    this.clearTint();
                } else {
                    this.setTint(0xff0000);
                }
            }
        });

        //Sound effect
        this.scene.sound.add("effect-hit", {
            volume: 0.7
        }).play();
    }

    //Visual
    updateState() {
        if (this.allStates) {
            this.setFrame(this.allStates[this.maxHealth - this.health])
            const body = this.body as Phaser.Physics.Arcade.Body;
            body.setSize(this.displayWidth, this.displayHeight);
        }
    }

    /**
     * set new states for different styless
     *  and put it in allStates
     */ 
    GetAllStateNames() {
        // rock is a static image
        if (this.enemyType === "rock") {
            return;
        }
        
        this.allStates = [];
        const choosenStyle = Phaser.Math.RND.integerInRange(1, this.styleAmount)
        // give for each health point a state to switch to
        for (let i = 0; i < this.maxHealth; i++) {
            // keys start counting on 1
            this.allStates.push(`enemy-${this.enemyType}-${choosenStyle}-${i + 1}`)
        }
    }

    /**
     * Check all the style available
     */
    SetStyleAmount() {
        const stateNames = this.texture.getFrameNames();
        
        // expected that each enemy has an expected max variety of 4
        for(let x = 1; x < 5; x++) {
            for (let i = 0; i < stateNames.length; i++) {
                const element = stateNames[i];
                
                if(element === `enemy-${this.enemyType}-${x}-1`) {
                    this.styleAmount++;
                }
            }
        }        
    }
}