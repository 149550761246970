import Phaser from "phaser";
import Snowflakes from "../components/controllers/snowflakes";

export default class Background extends Phaser.Scene {
    background!: Phaser.GameObjects.Image;
    overlay!: Phaser.GameObjects.Image;
    music!: Phaser.Sound.BaseSound;

    constructor() {
        super({
            key: "background"
        });
    }

    create() {
        this.background = this.add.image(this.cameras.main.centerX, 0, 'background').setOrigin(0.5, 0);
        this.background.setDisplaySize(this.cameras.main.width, this.cameras.main.height)

        //Update image scale
        const scale = this.sys.canvas.width / this.background.width;
        this.background.setScale(scale);


        const overlayTexture = this.createOverlay();
        this.overlay = this.add.image(0, 0, overlayTexture).setOrigin(0, 0).setAlpha(0);

        // Notify that game has booted
        this.game.events.emit("booted");
    }

    createOverlay(): string {
        if (!this.textures.exists('overlay')) {
            const textureBackground = this.make.graphics({ x: 0, y: 0, add: false });
            textureBackground.fillStyle(0x000000, 1);
            textureBackground.fillRect(0, 0, this.cameras.main.width, this.cameras.main.height);
            textureBackground.generateTexture('overlay', this.cameras.main.width, this.cameras.main.height);
        }

        return 'overlay';
    }

    setOverlayAlpha(alpha: number) {
        this.tweens.add({
            targets: this.overlay,
            alpha: alpha,
            duration: 800,
            ease: 'Power.1'
        })
    }

    setColor(color: string | number | Phaser.Types.Display.InputColorObject) {
        this.cameras.main.setBackgroundColor(color ? color : 0x000000);
    }

    playMusic() {
        this.stopMusic();

        //Create music instance & play
        this.music = this.sound.add("background-music");
        this.music.play({ loop: true, volume: 0.4 });
    }

    stopMusic() {
        if (this.music) {
            this.music.destroy();
        }
    }
}