import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import Scaling from "../configs/scaling";
import GameHelper from "../util/game-helper";

export default class Load extends Phaser.Scene {

    constructor() {
        super({
            key: "load"
        });
    }

    preload() {
        /** CAPE */
        const gameSettings = Setup.data.settings.game;

        //Add logo
        const logo = this.add.image(0, 0, "logo");
        logo.setDisplaySize(Scaling.LOAD_LOGO_WIDTH, GameHelper.calculateComponentHeight(logo.width, logo.height, Scaling.LOAD_LOGO_WIDTH));
        logo.setPosition(this.cameras.main.centerX, this.cameras.main.centerY + Scaling.HUD_DEFAULT_MARGIN);

        //Add background loader
        const loaderY = this.cameras.main.height * 0.90;
        const backgroundLoader = this.add.image(0, 0, "loader-empty");
        const backgroundLoaderWidth = this.sys.canvas.width * 6 / 8;
        backgroundLoader.setDisplaySize(backgroundLoaderWidth, GameHelper.calculateComponentHeight(backgroundLoader.width, backgroundLoader.height, backgroundLoaderWidth));
        backgroundLoader.setPosition(this.cameras.main.centerX, loaderY);

        //Add foreground loader
        const foregroundLoader = this.add.image(0, 0, "loader-filled");
        const foregroundLoaderWidth = backgroundLoaderWidth * (foregroundLoader.width / backgroundLoader.width);
        foregroundLoader.setDisplaySize(foregroundLoaderWidth, GameHelper.calculateComponentHeight(foregroundLoader.width, foregroundLoader.height, foregroundLoaderWidth));
        foregroundLoader.setPosition(this.cameras.main.centerX, loaderY);

        //Apply mask
        const loaderMaskGraphic = new Phaser.GameObjects.Graphics(this);
        loaderMaskGraphic.fillRect(foregroundLoader.x - foregroundLoader.displayWidth / 2, foregroundLoader.y - foregroundLoader.displayHeight / 2, foregroundLoader.displayWidth / 2, foregroundLoader.displayHeight);
        const loaderMask = new Phaser.Display.Masks.GeometryMask(this, loaderMaskGraphic);
        foregroundLoader.setMask(loaderMask);

        //Loading event
        this.load.on("progress", (value: number) => {
            loaderMaskGraphic.fillRect(foregroundLoader.x - foregroundLoader.displayWidth / 2, foregroundLoader.y - foregroundLoader.displayHeight / 2, foregroundLoader.displayWidth * value, foregroundLoader.displayHeight);
        });

        //Completion event
        this.load.on("complete", () => {
            this.setupAnimations();
            this.game.events.emit("loaded");
        });

        //Load images
        this.load.image("background-game", Scaling.ImagePath("background_game", "png"));
        this.load.image("background-waves-left", Scaling.ImagePath("waves_left", "png"));
        this.load.image("background-waves-right", Scaling.ImagePath("waves_right", "png"));
        this.load.image("background-points", Scaling.ImagePath("bg_points", "png"));
        this.load.image("background-lifes", Scaling.ImagePath("bg_lifes", "png"));
        this.load.image("health", Scaling.ImagePath("icon_life_fill", "png"));
        this.load.image("health-empty", Scaling.ImagePath("icon_life_empty", "png"));
        this.load.image("upgrade-life", Scaling.ImagePath("upgrade_life", "png"));
        this.load.image("upgrade-shield", Scaling.ImagePath("upgrade_shield", "png"));
        this.load.image("upgrade-fire", Scaling.ImagePath("upgrade_fire", "png"));
        this.load.image("bullet-player", Scaling.ImagePath("sprite_ammunition", "png"));

        //Load atlas
        this.load.atlas('enemy', Scaling.ImagePath("enemy_sprites", "png"), Scaling.ImagePath("enemy_spritesAtlas", "json"));
        this.load.atlas("player", Scaling.ImagePath("sprite_player", "png"), Scaling.ImagePath("sprite_player_atlas", "json"))

        // load spritesheet
        this.load.spritesheet("explosion", Scaling.ImagePath("sprite_hit", "png"), {
            frameWidth: Scaling.getPixelbyDPR(64),
            frameHeight: Scaling.getPixelbyDPR(64)
        });

        //Load sounds
        this.load.audio("background-music", Setup.getSound("music_action.mp3"));
        this.load.audio("laser-player", Setup.getSound("laser_player.mp3"));
        this.load.audio("laser-mini", Setup.getSound("laser_mini.mp3"));
        this.load.audio("effect-death", Setup.getSound("effect_death.mp3"));
        this.load.audio("effect-hit", Setup.getSound("effect_hit.mp3"));
        this.load.audio("effect-powerup", Setup.getSound("effect_powerup.mp3"));
        this.load.audio("effect-powerup-drop", Setup.getSound("effect_powerup_drop.mp3"));
    }

    setupAnimations() {

        this.anims.create({
            key: "run-explosion",
            frames: this.anims.generateFrameNumbers("explosion", { start: 0, end: 5 }),
            frameRate: 12
        });
    }
}


