import Setup from "@/helpers/Setup";
import { ScoreDataDefault } from "@/interfaces/ISessionFile";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import Phaser, { Cameras, Tweens } from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import UI_Box from "./ui_box";
import UI_Image from "./ui_image";
import UI_Text from "./ui_text";

export default class HUD extends Phaser.GameObjects.Container {
    score: number;
    health: number;
    scoreBox: UI_Box;
    healthBox: UI_Box;


    constructor(scene: Phaser.Scene) {
        super(scene, 0, 0);
        this.scene.add.existing(this);
        this.setDepth(Constants.DEPTH_HUD);

        //Set data
        this.score = 0;
        this.health = Constants.HEALTH_PLAYER

        const useImageAsHealthUI = Setup.getValue('settings.game.useImageAsHealthUI', false);

        if(useImageAsHealthUI) {
            this.healthBox = new UI_Image(this.scene, scene.cameras.main.width - Scaling.getPixelbyDPR(10), Scaling.getPixelbyDPR(20), 'background-lifes', 1)
        } else {
            this.healthBox = new UI_Text(this.scene, scene.cameras.main.width - Scaling.getPixelbyDPR(10), Scaling.getPixelbyDPR(20), 'background-lifes', 1, "999x", 1, Scaling.getPixelbyDPR(12), true)
        }
        this.updateHealth(this.health);

        //Draw score box
        this.scoreBox = new UI_Text(this.scene, Scaling.getPixelbyDPR(10), Scaling.getPixelbyDPR(20), 'background-points', 0, "999", -1, -Scaling.getPixelbyDPR(32), false);
        this.updateScore("alive", 0)
        this.add([this.scoreBox, this.healthBox]);
    }

    getTitleOptions(): Phaser.Types.GameObjects.Text.TextStyle {
        return {
            fontFamily: Constants.FONT_REGULAR,
            fontSize: `${Scaling.HUD_TITLE_SIZE}px`,
            resolution: Scaling.DPR,
            color: Setup.getColor('text'),
            fixedWidth: Scaling.HUD_SCORE_TEXT_SPACE_WIDTH,
            align: "left"
        };
    }

    //Update functions
    updateHealth(health: number) {
        const symbol = Setup.getValue('settings.game.useX', "").value 
        this.healthBox.updateValue(health)
    };

    // TODO not sure what the type "type" is
    // HUD => main => ??
    updateScore(type: any, points: number) {
        this.score += points;
        sessionSaveFile.incrementValue(ScoreDataDefault.TOTALSCORE, points);

        this.scoreBox.updateValue(this.score);
        this.emit("score", type, this.score);
    }


}
