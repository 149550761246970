import Setup from "@/helpers/Setup";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import UI_Box from "./ui_box";

export default class UI_Text extends UI_Box {
    uiText!: Phaser.GameObjects.Text;
    symbol: string
    textXorigin: number
    textPositionX: number

    constructor(scene: Phaser.Scene, x: number, y: number, backgroundKey: string, xOrigin: number, starterText: string, textXorigin: number, textPositionX: number, useSymbol: boolean) {
        super(scene, x, y, backgroundKey, xOrigin)

        this.textXorigin = textXorigin;
        this.textPositionX = textPositionX

        // check if symbol will be used
        if(useSymbol) {
            this.symbol = Setup.getValue('settings.game.healthUIText', { value: "" }).value
        } else {
            this.symbol = ""
        }
        const uiItems = this.createText(starterText);

        this.add(uiItems)
    }

    updateValue(value: number) {        
        this.uiText.setText(`${value}${this.symbol}`)
    }

    createText(starterText: string) {
        this.uiText = this.scene.add.text(this.textPositionX, this.uiBackground.height / 2, starterText, this.getTextOptions()).setOrigin(this.textXorigin, 0.5);

        return [this.uiText];
    }

    //Default text options
    getTextOptions(): Phaser.Types.GameObjects.Text.TextStyle {
        return {
            fontFamily: Constants.FONT_BOLD,
            fontSize: `${Scaling.HUD_TEXT_SIZE}px`,
            resolution: Scaling.DPR,
            color: Setup.getValue('design.text.textAlternate', 0xFFFFFF),
            fixedWidth: Scaling.HUD_SCORE_TEXT_SPACE_WIDTH,
            align: "center"
        };
    }
}