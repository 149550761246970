import Constants from "../../configs/constants";
import Difficulty from "../../configs/difficulty";
import Scaling from "../../configs/scaling";
import Main from "../../states/main";
import Player from "../player";
import BaseBullet from "./base-bullet";

export default class PlayerBullet extends BaseBullet {
    player: Player
    bulletSoundeffect: Phaser.Sound.BaseSound;

    constructor(scene: Phaser.Scene, player: Player, playOnSpawn = false) {
        super(scene, Constants.CATEGORY_PLAYER, "bullet-player");

        //Set data
        this.direction = -1;
        this.speed = Scaling.PLAYER_BEAM_SPEED * Difficulty.SPEED_MULTIPLIER;
        this.player = player

        //Set starting position
        this.setPosition(player.x, player.y - (player.displayHeight / 2) + (this.displayHeight / 2));

        //Play sound
        this.bulletSoundeffect = this.scene.sound.add("laser-player", {
            rate: 1.5
        })
        if(playOnSpawn){
            this.bulletSoundeffect.play()
        }
    }

    alive() {
        super.alive()
        this.bulletSoundeffect.play()
        this.setPosition(this.player.x, this.player.y - (this.player.displayHeight / 2) + (this.displayHeight / 2));
    }
}
