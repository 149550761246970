import { random } from "lodash";
import Phaser from "phaser";
import Constants from "../configs/constants";
import Difficulty from "../configs/difficulty";
import Scaling from "../configs/scaling";
import Main from "../states/main";
import GameHelper from "../util/game-helper";

export default class PowerUp extends Phaser.GameObjects.Container {
    isOutOfBounds: boolean;
    speed: number;
    powerType: string

    declare scene: Main;
    constructor(scene: Phaser.Scene) {
        super(scene);
        this.scene.add.existing(this);

        //Set physics

        this.scene.physics.world.enable(this);
        this.scene.powerUpColliderGroup.add(this);

        this.powerType = ""
        this.setImageKey()

        //Set data
        this.active = true;
        this.isOutOfBounds = false;
        this.speed = Scaling.SPEED_POWER_UP * Difficulty.SPEED_MULTIPLIER

        scene.game.events.emit('setPowerupSound', true)
    }

    update() {
        if (!this.active)
            return;

        //Move down
        this.y += this.speed;

        //Bounds check
        this.isOutOfBounds = this.y > this.scene.sys.canvas.height + this.height * 2;
        if(this.isOutOfBounds) {
            this.death(true)
        }
    }

    alive() {
        this.setVisible(true)
        this.setActive(true)
        this.active = true
        this.setImageKey()
        this.scene.game.events.emit('setPowerupSound', true)
    }

    death(silent = false) {
        if (!this.active)
            return;

        //Cleanup
        this.scene.game.events.emit('setPowerupSound', false)
        this.setVisible(false);
        this.setActive(false)

        //Only play sound, when not silent
        if (!silent)
            this.scene.sound.add("effect-powerup").play();
    }

    setImageKey() {
        //Determine visual 
        this.powerType = this.getRandomType();

        let baseVisual = "upgrade-";
        switch (this.powerType) {
            default:
            case Constants.POWERUP_LIFE:
                baseVisual += "life";
                break;
            case Constants.POWERUP_FIRE_RATE:
                baseVisual += "fire";
                break;
            case Constants.POWERUP_SHIELD:
                baseVisual += "shield";
                break;
            case Constants.POWERUP_CLEARROOM:
                baseVisual += "clear"
                break;
        }

        //Load base visual
        let visuals
        if (this.first === null) {
            visuals = new Phaser.GameObjects.Image(this.scene, 0, 0, baseVisual);
            // visuals.setDisplaySize(Scaling.POWERUP_WIDTH, GameHelper.calculateComponentHeight(visuals.width, visuals.height, Scaling.POWERUP_WIDTH));
            this.add(visuals);
        } else {
            visuals = this.first as Phaser.GameObjects.Image;
            visuals.setTexture(baseVisual)
            // visuals.setDisplaySize(Scaling.POWERUP_WIDTH, GameHelper.calculateComponentHeight(visuals.width, visuals.height, Scaling.POWERUP_WIDTH));
        }

        //Set position
        const arcadeBody = this.body as Phaser.Physics.Arcade.Body
        arcadeBody.setSize(visuals.displayWidth, visuals.displayHeight);
        this.setSize(visuals.displayWidth, visuals.displayHeight);
        this.setPosition(Phaser.Math.RND.between(this.width / 2, this.scene.sys.canvas.width - this.width / 2), -(this.height / 2));
    }

    getRandomType() {
        const randomIndex = Phaser.Math.RND.between(1, 3);
        switch (randomIndex) {
            case 1:
                return Constants.POWERUP_LIFE;
            case 2:
                return Constants.POWERUP_SHIELD;
            case 3:
                return Constants.POWERUP_FIRE_RATE;
            default:
                console.log(`unexpected index: ${randomIndex}`)
                return ""
            // case 4:
            //     return Constants.POWERUP_CLEARROOM
        }
    }
}
