import Phaser from "phaser";
import Constants from "../configs/constants";
import Difficulty from "../configs/difficulty";
import Scaling from "../configs/scaling";
import GameHelper from "../util/game-helper";
import BaseEnemy from "./enemies/base-enemy";
import Explosion from "./explosion";

export default class Player extends Phaser.GameObjects.Container {
    category: string;
    isGodMode: boolean;
    health: number;
    playerSprite: Phaser.GameObjects.Sprite;
    shieldSprite: Phaser.GameObjects.Sprite;
    shootTimer: Phaser.Time.TimerEvent;
    emitter!: Phaser.GameObjects.Particles.ParticleEmitter;
    damageTween!: Phaser.Tweens.Tween | null;
    powerUpShieldTimer!: Phaser.Tweens.Tween | null;
    powerUpFireTimer!: Phaser.Time.TimerEvent | null;
    screenFlash!: Phaser.GameObjects.Rectangle
    screenFlashTween!: Phaser.Tweens.Timeline
    speedAccelerate: number

    constructor(scene: Phaser.Scene) {
        super(scene, 0, 0);
        this.scene.add.existing(this);
        this.setName("player")
        this.speedAccelerate = 0

        //Set data
        this.category = Constants.CATEGORY_PLAYER;
        this.isGodMode = false;
        this.health = Constants.HEALTH_PLAYER;

        //Setup default sprite & size
        this.playerSprite = new Phaser.GameObjects.Sprite(this.scene, 0, 0, "player", "player-normal");
        // this.playerSprite.setDisplaySize(Scaling.PLAYER_WIDTH, GameHelper.calculateComponentHeight(this.playerSprite.width, this.playerSprite.height, Scaling.PLAYER_WIDTH));
        this.setSize(this.playerSprite.displayWidth, this.playerSprite.displayHeight);
        this.add(this.playerSprite);

        //Setup shield
        this.shieldSprite = new Phaser.GameObjects.Sprite(this.scene, this.playerSprite.getCenter().x, this.playerSprite.getCenter().y, "shield").setOrigin(0.5);
        const shieldWidth = (this.shieldSprite.width / this.playerSprite.width) * Scaling.PLAYER_WIDTH;
        // this.shieldSprite.setDisplaySize(shieldWidth, GameHelper.calculateComponentHeight(this.shieldSprite.width, this.shieldSprite.height + Scaling.getPixelbyDPR(30), shieldWidth));
        this.shieldSprite.setAlpha(0);
        this.add(this.shieldSprite);


        //Apply physics
        this.scene.physics.world.enable(this);
        const arcadeBody = this.body as Phaser.Physics.Arcade.Body
        arcadeBody.setCollideWorldBounds(true);
        arcadeBody.setSize(this.playerSprite.displayWidth / 2, this.playerSprite.displayHeight, false);
        arcadeBody.setOffset(this.playerSprite.displayWidth / 4, 0);

        //Set initial position
        const initX = this.scene.sys.canvas.width / 2;
        const initY = this.scene.sys.canvas.height - this.playerSprite.displayHeight;
        this.setPosition(initX, initY);
        this.setDepth(Constants.DEPTH_PLAYER);

        //Set dragging interaction
        this.setInteractive();
        this.scene.input.setDraggable(this);
        this.on("drag", (pointer: Phaser.Input.Pointer, dragX: number, dragY: number) => this.setPosition(dragX, dragY));

        //Start shootin'
        this.shootTimer = this.scene.time.addEvent({
            delay: Constants.PLAYER_BASE_FIRE_RATE,
            repeat: -1,
            callback: () => this.emit("shoot")
        });


        const screenFlashColor = Phaser.Display.Color.GetColor(255, 255, 255) // white
        this.screenFlash = scene.add.rectangle(0, 0, this.scene.cameras.main.displayWidth, this.scene.cameras.main.displayHeight, screenFlashColor).setPosition(scene.cameras.main.centerX, scene.cameras.main.centerY).setAlpha(0)
    }

    hit() {
        if (this.isGodMode)
            return;

        //Subtract health
        this.setHealth(-1);

        //Set frame or set game over
        if (this.health !== 0) {
            this.damageEffect();
        } else {
            this.emit("pre-death");
        }
    }

    death() {
        if (!this.active)
            return;

        this.active = false;
        this.setVisible(false);

        //Boom
        const explosion = new Explosion(this.scene, this.x, this.y);
        explosion.on("completed", () => {
            this.emit("death");
        });
        this.scene.sound.add("effect-death").play();
    }

    setHealth(value: number) {
        this.health += value;

        //Limit health & emit event
        if (this.health > Constants.HEALTH_PLAYER) {
            this.health = Constants.HEALTH_PLAYER;
        }
        this.emit("health", this.health);
    }

    damageEffect() {
        if (this.damageTween)
            this.damageTween.remove();

        //Begin animation
        this.isGodMode = true;
        this.damageTween = this.scene.tweens.add({
            targets: this.playerSprite,
            alpha: 0,
            yoyo: true,
            repeat: 5,
            duration: 50,
            onStart: () => {
                this.updateFrame("hit");
            },
            onComplete: () => {
                if (this.damageTween !== null) {
                    this.damageTween.remove();
                }
                this.updateFrame("normal");
                this.damageTween = null;
                this.isGodMode = false;
            }
        });

        //Damage sound
        this.scene.sound.add("effect-hit", {
            volume: 0.7
        }).play();
    }

    applyPowerUp(type: string, enemies?: BaseEnemy[]) {
        switch (type) {
            default:
            case Constants.POWERUP_LIFE:
                if (this.health < Constants.HEALTH_PLAYER) {
                    this.setHealth(1);
                }
                break;
            case Constants.POWERUP_SHIELD:
                if (this.powerUpShieldTimer) {
                    this.powerUpShieldTimer.remove();
                    this.powerUpShieldTimer = null;
                }

                //Set god mode
                this.updateFrame("shield");
                this.isGodMode = true;

                //Reset timer to return to normal fire rate
                this.powerUpShieldTimer = this.scene.add.tween({
                    targets: this.shieldSprite,
                    alpha: 0,
                    duration: Constants.POWERUP_SHIELD_DURATION,
                    onComplete: () => {
                        this.powerUpShieldTimer = null;
                        this.isGodMode = false;
                        this.damageTween = null;
                        this.updateFrame("normal")
                    }
                });
                break;
            case Constants.POWERUP_FIRE_RATE:
                if (this.powerUpFireTimer) {
                    this.powerUpFireTimer.remove();
                    this.powerUpFireTimer = null;
                }

                //Set boosted fire rate
                this.shootTimer.reset({
                    delay: Math.round(Constants.PLAYER_POWERED_FIRE_RATE / Difficulty.SPEED_MULTIPLIER),
                    repeat: -1,
                    callback: () => {
                        this.emit("shoot")
                        this.updateFrame("boost")
                    }
                });

                //Reset timer to return to normal fire rate
                this.powerUpFireTimer = this.scene.time.addEvent({
                    delay: Constants.POWERUP_FIRE_RATE_DURATION,
                    callback: () => {
                        this.powerUpFireTimer = null;
                        
                        this.updateFrame("normal")
                        this.resetFireRate();
                    }
                });
                break;
            case Constants.POWERUP_CLEARROOM:
                if (enemies === undefined) {
                    return
                }

                this.clearScreen(() => this.setpauseEnemies(true, enemies), () => {
                    this.clearEnemies(enemies)
                    this.scene.cameras.main.shake(Constants.POWERUP_CLEARROOM_FLASH_TIME, 0.025)
                },
                    () => this.setpauseEnemies(false, enemies))
                break;
        }
    }

    updateFrame(frameName: string) {
        this.playerSprite.setFrame(`player-${frameName}`);
    }

    resetFireRate() {
        if (!this.powerUpFireTimer) {
            this.shootTimer.reset({
                delay: Math.round(Constants.PLAYER_BASE_FIRE_RATE / Difficulty.SPEED_MULTIPLIER),
                repeat: -1,
                callback: () =>
                { 
                    this.emit("shoot")
                }
            });
        }
    }

    SetSpeedUp(speed: number) {
        
        if(this.speedAccelerate + speed < 0) {
            this.speedAccelerate = 0
            return
        }

        this.speedAccelerate += speed
        this.scene.game.events.emit("speedUp", Scaling.getPixelbyDPR(speed))
    }

    /**
 * flash to white with a bunch of callback
 * @param startCallBack callback at the start of the timeline
 * @param onCallback callback at the midpoint of the timeline (when the screen is comepletly white)
 * @param endCallback callback at the end of the timeline use this to reset variables
 */
    clearScreen(startCallBack: Phaser.Types.Tweens.TweenOnCompleteCallback, onCallback: Phaser.Types.Tweens.TweenOnCompleteCallback, endCallback: Phaser.Types.Tweens.TweenOnCompleteCallback) {
        if (this.screenFlashTween) {
            this.screenFlashTween.stop()
        }

        this.screenFlashTween = this.scene.tweens.timeline({
            targets: this.screenFlash,
            ease: Phaser.Math.Easing.Quadratic.InOut,
            duration: 1000,
            paused: false,
            tweens: [{
                alpha: { from: 0, to: 1 },
                onStart: startCallBack,
                callbackScope: this.scene,
            }, {
                duration: Constants.POWERUP_CLEARROOM_FLASH_TIME,
                onStart: onCallback,
                callbackScope: this.scene,
                alpha: 1,
            }, {
                alpha: { from: 1, to: 0 },
                onComplete: endCallback
            }]
        })
    }

    clearEnemies(enemies: BaseEnemy[]) {
        enemies.forEach((enemy) => {
            enemy.death(true)
            enemy.emit("death", 5, enemy);
        })
    }

    setpauseEnemies(state: boolean, enemies: BaseEnemy[]) {
        enemies.forEach((enemy) => {
            enemy.isPaused = state
        })
        // stop/start spawning enemies 
        this.emit("SetSpawnStatus", state)
    }

}
